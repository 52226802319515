import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shehin/first-project/mykare-blog-web/app/home/components/ArticlesCardList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shehin/first-project/mykare-blog-web/app/home/components/blogCard/SocialShare.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shehin/first-project/mykare-blog-web/app/home/components/surgerySection/SurgeryContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shehin/first-project/mykare-blog-web/components/DelayedFormPopup.tsx");
;
import(/* webpackMode: "eager" */ "/home/shehin/first-project/mykare-blog-web/components/DynamicText.tsx");
;
import(/* webpackMode: "eager" */ "/home/shehin/first-project/mykare-blog-web/components/GenericForm.tsx");
;
import(/* webpackMode: "eager" */ "/home/shehin/first-project/mykare-blog-web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/shehin/first-project/mykare-blog-web/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/shehin/first-project/mykare-blog-web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/shehin/first-project/mykare-blog-web/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/shehin/first-project/mykare-blog-web/public/homePage/BlogSideBar/ad small@3x copy.webp");
;
import(/* webpackMode: "eager" */ "/home/shehin/first-project/mykare-blog-web/public/homePage/BlogSideBar/ad small@3x.webp");
;
import(/* webpackMode: "eager" */ "/home/shehin/first-project/mykare-blog-web/public/homePage/BlogSideBar/ellipsis.svg");
;
import(/* webpackMode: "eager" */ "/home/shehin/first-project/mykare-blog-web/public/homePage/FeatureImage.webp");
